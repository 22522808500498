import React from 'react'

const HowItWorks = () => {
  return (
    <section class="bg-black text-white sectionSize scroll-mt-24" id="how-it-works">
      <div>
        <h2 class="secondaryTitle bg-underline2 bg-100% text-white">How it works</h2>
      </div>
      <div class="flex flex-col md:flex-row">
        <div class="flex-1 mx-8 flex flex-col items-center my-4">
          <div class="border-2 rounded-full bg-secondary text-black h-12 w-12 flex justify-center items-center mb-3">
            1
          </div>
          <h3 class="font-montserrat font-medium text-xl mb-2 text-white">Apply</h3>
          <p class="text-center font-montserrat">
            Apply for Global Entry on the <a href="https://ttp.dhs.gov/" class="underline">Department of Homeland Security's (DHS) website</a>, and get pre-approved.
          </p>
        </div>
        <div class="flex-1 mx-8 flex flex-col items-center my-4">
          <div class="border-2 rounded-full bg-secondary text-black h-12 w-12 flex justify-center items-center mb-3">
            2
          </div>
          <h3 class="font-montserrat font-medium text-xl mb-2 text-white">Sign Up</h3>
          <p class="text-center font-montserrat">
            Once you've received your pre-approval, click sign up to select your locations and set up your profile.
          </p>
        </div>
        <div class="flex-1 mx-8 flex flex-col items-center my-4">
          <div class="border-2 rounded-full bg-secondary text-black h-12 w-12 flex justify-center items-center mb-3">
            3
          </div>
          <h3 class="font-montserrat font-medium text-xl mb-2 text-white">Get Notified</h3>
          <p class="text-center font-montserrat">
            You will start receiving notifications whenever there is an available slot for your selected locations.
          </p>
        </div>
      </div>
    </section>
  )
}

export default HowItWorks;