import React, { useEffect, useState } from 'react'
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from './CheckoutForm';
import { PROMO_KEY, REFERRAL_KEY, REST_DOMAIN, STRIPE_PUBLISHABLE_KEY } from './Util';
import Loading from './Loading';
import { useUserContext } from '../contexts/UserContext';
import { useCheckoutContext } from '../contexts/CheckoutContext';
import { Products } from '../enums/Products';
import { useSearchParams } from 'react-router-dom';
import useProductHook from '../hooks/useProuductPrice';
import SuccessfulPayment from './SuccessfulPayment';

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);
const defaultClientSecret = new URLSearchParams(window.location.search).get(
  "payment_intent_client_secret"
);

function FreePayment({ product }) {
  const { user } = useUserContext();
  const [searchParams] = useSearchParams();
  const { selectedLocations, username, phoneNumber } = useCheckoutContext();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState();

  useEffect(() => {
    const metaData = {
      user_id: user.uid, channel_ids: selectedLocations.map(location => location.id), referral: searchParams.get(REFERRAL_KEY), promo: searchParams.get(PROMO_KEY)
    };

    if (product === Products.DISCORD) {
      metaData['discord_username'] = username;
    } else if (product === Products.SMS) {
      metaData['phone_number'] = phoneNumber;
    }
    fetch(`${REST_DOMAIN}/promo`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ items: [], ...metaData }),
    })
      .then((res) => res.json())
      .catch((e) => setError(e))
      .finally(() => setIsLoading(false));
  }, [phoneNumber, product, searchParams, selectedLocations, user.uid, username])

  if (isLoading) {
    return <div class="max-w-lg mx-auto my-10 bg-white p-8 rounded-xl shadow shadow-slate-300">
      <Loading />
    </div>
  }

  if (error != null) {
    throw new Error('Error processing promo code');
  }

  return <div class="max-w-lg mx-auto my-10 bg-white p-8 rounded-xl shadow shadow-slate-300"><SuccessfulPayment /></div>

}

function PaymentWrapper({ product }) {
  const [price, isLoading] = useProductHook(product);

  if (isLoading) {
    return <div class="max-w-lg mx-auto my-10 bg-white p-8 rounded-xl shadow shadow-slate-300">
      <Loading />
    </div>;
  }

  if (price === 0) {
    return <FreePayment product={product} />
  }

  return <Payment product={product} />
}

function Payment({ product }) {
  const [clientSecret, setClientSecret] = useState(defaultClientSecret);
  const { user } = useUserContext();
  const { selectedLocations, username, phoneNumber } = useCheckoutContext();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (!clientSecret) {
      const metaData = {
        user_id: user.uid, channel_ids: selectedLocations.map(location => location.id), referral: searchParams.get(REFERRAL_KEY), promo: searchParams.get(PROMO_KEY)
      };

      if (product === Products.DISCORD) {
        metaData['discord_username'] = username;
      } else if (product === Products.SMS) {
        metaData['phone_number'] = phoneNumber;
      }
      // Create PaymentIntent as soon as the page loads
      fetch(`${REST_DOMAIN}/create-payment-intent`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ items: [], ...metaData }),
      })
        .then((res) => res.json())
        .then((data) => setClientSecret(data.clientSecret));
    }
  }, [user.uid, selectedLocations, username, clientSecret, product, phoneNumber, searchParams]);

  const appearance = {
    theme: 'stripe',
  };

  const options = {
    clientSecret,
    appearance,
  };

  return (
    <div class="max-w-lg mx-auto my-10 bg-white p-8 rounded-xl shadow shadow-slate-300">
      {clientSecret == null ? <Loading /> : (
        <Elements options={options} stripe={stripePromise} key={clientSecret}>
          <CheckoutForm product={product} clientSecret={clientSecret} />
        </Elements>
      )}
    </div>
  )
}

export default PaymentWrapper