import React from "react";
import CheckIcon from "../assets/img/CheckedBox.svg";
import { SMS_PRICE } from "./Util";
import useProductHook from "../hooks/useProuductPrice";
import { Products } from "../enums/Products";

function SMSPriceCard() {
  const [discordPrice] = useProductHook(Products.SMS);

  const price = discordPrice == null ? SMS_PRICE : discordPrice;
  const isDiscounted = price !== SMS_PRICE;

  return (
    <div class="flex-1 flex flex-col mx-6 shadow-2xl relative bg-secondary rounded-2xl py-5 px-8 my-8 md:top-12 max-w-sm">
      <h3 class="font-pt-serif font-normal text-2xl mb-4 line-through">
        SMS Notifications
      </h3>
      <div class="font-montserrat font-bold text-2xl mb-4">
        <span class={`${isDiscounted && "line-through"} line-through`}>
          ${SMS_PRICE}
        </span>
        {isDiscounted && <span> ${price}</span>}
        <span class="font-normal text-base line-through"> for 30 days</span>
      </div>
      <div class="flex">
        <img src={CheckIcon} alt="" class="mr-1" />
        <p>Choose up to 5 enrollment centers</p>
      </div>
      <div class="flex">
        <img src={CheckIcon} alt="" class="mr-1" />
        <p>No recurring payments</p>
      </div>
      <div class="flex">
        <img src={CheckIcon} alt="" class="mr-1" />
        <p>No extra application download</p>
      </div>
      <div class="flex">
        <img src={CheckIcon} alt="" class="mr-1" />
        <p>Instant text notification</p>
      </div>
      <div class="flex">
        <img src={CheckIcon} alt="" class="mr-1" />
        <p>100% money back guarantee*</p>
      </div>
      <div class="flex">
        <img src={CheckIcon} alt="" class="mr-1" />
        <p>Mute notifications at any time</p>
      </div>
      <button
        class="w-full border-2 border-solid border-black rounded-xl text-lg py-3 mt-4 disabled:bg-gray-300"
        disabled
      >
        Discontinued
      </button>
      <div class="mt-3">
        <p class="text-xs">* We are no longer offering SMS notifications.</p>
      </div>
    </div>
  );
}

export default SMSPriceCard;
