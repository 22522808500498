import React from 'react'
import FAQItem from './FAQItem';

const FAQ = () => {

  return (
    <section class="sectionSize items-start pt-8 md:pt-36 bg-black text-white" id="faq">
      <div>
        <h2 class="secondaryTitle bg-highlight3 p-10 mb-0 bg-center bg-100% text-white">
          FAQ
        </h2>
      </div>
      <FAQItem title="How does it work?" >
        <p>Global Entry Notif is a notification system to alert users of new appointments available within the next month.
          Our system is tightly integrated with Discord, a messaging platform, to provide our users with a great user experience
          that Discord already provides. Whenever we detect a new appointment opening we will automatically ping the
          channel assigned to that location.

          <br /><br />

          Global Entry Notif does not sell nor guarantee appointments. We are not affiliated with or endorsed by any government agency.
          We cannot access any of your Trusted Traveler Program account information. We simply provide our users a notification
          of appointment availablity, and it is the user's responsibility to login and claim the appointment before it is claimed by others.</p>
      </FAQItem>
      <FAQItem title="Is this a subscription?" >
        <p>No, this is not a subscription service. Our price is for 30 days of notifications and after that you will automatically
          be removed from receiving notifications. Our users on average find an appointment within the first week of the service.
          If you are unable to find an appointment within 30 days you have an option to purchase another 30 days from our website. </p>
      </FAQItem>
      <FAQItem title="How do you handle our sensitive payment data?" >
        <p> Payments for our service are all handled through a reliable third party provider Stripe. Stripe is a popular payment
          processing and merchant services company. It is accredited with the Better Business Bureau and maintains an A+ rating. </p>
      </FAQItem>
    </section>
  )
}

export default FAQ