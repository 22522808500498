import React, { useCallback, useState } from 'react'
import { sendEmailLink, signInWithGoogle, signInWithFacebook } from "../firebase";
import Loading from './Loading';


// Get started here https://blog.logrocket.com/user-authentication-firebase-react-apps/
// Beneficial to read offical docs as well 
function Login() {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  const handleEmailLink = useCallback(() => {
    setIsLoading(true);
    sendEmailLink(email)
      .then(() => {
        setIsLoading(false);
        setEmailSent(true);
      })
  }, [email]);

  return (
    <div class="max-w-lg mx-auto my-10 bg-white p-8 rounded-xl shadow shadow-slate-300">
      <div >
        <button onClick={signInWithGoogle} class="w-full text-center py-3 my-3 border flex space-x-2 items-center justify-center border-slate-200 rounded-lg text-slate-700 hover:border-slate-400 hover:text-slate-900 hover:shadow transition duration-150">
          <img src="https://www.svgrepo.com/show/355037/google.svg" class="w-6 h-6" alt="" /> <span>Login with Google</span>
        </button>
        <button onClick={signInWithFacebook} class="w-full text-center py-3 my-3 border flex space-x-2 items-center justify-center border-slate-200 rounded-lg text-slate-700 hover:border-slate-400 hover:text-slate-900 hover:shadow transition duration-150">
          <img src="https://www.svgrepo.com/show/158427/facebook.svg" class="w-6 h-6" alt="" /> <span>Login with Facebook</span>
        </button>
      </div>
      <div class="my-10">
        {emailSent ? <button disabled={true} class="w-full py-3 font-medium text-white bg-green-500 rounded-lg border-black-500 hover:shadow inline-flex space-x-2 items-center justify-center">Check email for login link</button>
          :
          <div class="flex flex-col space-y-5">
            <label for="email">
              <p class="font-medium text-slate-700">Login with email</p>
              <p class="text-xs">Authentication link will be sent</p>
              <input onChange={(e) => setEmail(e.target.value)} id="email" name="email" type="email" class="mt-1 w-full py-3 border border-slate-200 rounded-lg px-3 focus:outline-none focus:border-slate-500 hover:shadow" placeholder="Enter email address" />
            </label>
            <button onClick={handleEmailLink} class="w-full py-3 font-medium text-white bg-black hover:bg-gray-900 rounded-lg border-black-500 hover:shadow inline-flex space-x-2 items-center justify-center">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1" />
              </svg>
              {isLoading ? <Loading /> : <span>Login</span>}
            </button>
          </div>}
      </div>
    </div>
  )
}

export default Login