import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import Loading from "./Loading";
import SuccessfulPayment from "./SuccessfulPayment";


export default function CheckoutForm({ product, clientSecret }) {
  const stripe = useStripe();
  const elements = useElements();

  const [paymentStatus, setPaymentStatus] = useState(null);
  const [message, setMessage] = useState(null);
  const [amount, setAmount] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [paymentIntentLoading, setPaymentIntentLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      setPaymentIntentLoading(true);
      await stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
        setAmount(paymentIntent.amount);

        const clientSecretURL = new URLSearchParams(window.location.search).get(
          "payment_intent_client_secret"
        );

        if (!clientSecretURL) {
          return;
        }

        setPaymentStatus(paymentIntent.status);
        switch (paymentIntent.status) {
          case "processing":
            setMessage("Your payment is processing.");
            break;
          case "requires_payment_method":
            setMessage("Your payment was not successful, please try again.");
            break;
          default:
            setMessage("Something went wrong.");
            break;
        }
      }).catch(() => {
        setMessage("Something went wrong.");
      });
      setPaymentIntentLoading(false);
    }

    if (!stripe) {
      return;
    }

    fetchData();

  }, [clientSecret, stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${window.location.href}`,
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occurred.");
    }

    setIsLoading(false);
  };

  if (paymentIntentLoading) {
    return <Loading />
  }

  if (paymentStatus === 'succeeded') {
    return <SuccessfulPayment />
  }

  return (
    <div>
      <p class="font-bold text-4xl text-slate-700">{`$${amount / 100}`}</p>
      <p class="font-medium text-2xl text-slate-700">{`30 Days of Notifications`}</p>
      <form id="payment-form" onSubmit={handleSubmit} class="mt-3">
        <PaymentElement id="payment-element" />
        <div class='mt-3 flex justify-end	'>
          {isLoading ? <Loading /> : <button onClick={() => null} disabled={isLoading || !stripe || !elements} id="submit" class="disabled:opacity-25 bg-black px-3 py-1 rounded-lg border border-gray-200 text-white mr-2 mb-2">
            <span id="button-text">
              Pay Now
            </span>
          </button>}
        </div>
        {/* Show any error or success messages */}
        {message && <div id="payment-message">{message}</div>}
      </form>
    </div>
  );
}