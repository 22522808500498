import moment from "moment";

export function locationQuery(location, query) {
  return Object.values(location).some(value => value.toString().toLowerCase().includes(query.toLowerCase()));
}

export function locationLabel(location) {
  return `${location['id']} - ${location['name']} (${location['city']}, ${location['state']})`
}

export function getFormattedDate(timestamp) {
  return moment.unix(timestamp).format("MM/DD/YYYY LTS")
}

// TTL in MS
export function setLocalWithTTL(key, value, ttl) {
  const now = new Date()

  // `item` is an object which contains the original value
  // as well as the time when it's supposed to expire
  const item = {
    value: value,
    expiry: now.getTime() + ttl,
  }
  localStorage.setItem(key, JSON.stringify(item))

  return value;
}

export function getLocalWithTTL(key) {
  const itemStr = localStorage.getItem(key)
  // if the item doesn't exist, return null
  if (!itemStr) {
    return null
  }
  const item = JSON.parse(itemStr)
  const now = new Date()
  // compare the expiry time of the item with the current time
  if (now.getTime() > item.expiry) {
    // If the item is expired, delete the item from storage
    // and return null
    localStorage.removeItem(key)
    return null
  }
  return item.value
}


export const DISCORD_PRICE = 20;
export const SMS_PRICE = 25;
// Start web Flask server on 4242 
export const REST_DOMAIN = process.env.NODE_ENV === 'development' ? 'http://localhost:4242' : "https://global-entry-notif.herokuapp.com";

// See your keys here: https://dashboard.stripe.com/apikeys
export const STRIPE_PUBLISHABLE_KEY = process.env.NODE_ENV === 'development' ?
  "pk_test_51LWrZyKbW5COXODM3SEIqan7zZWarFLss0m0E0YDmIQXn9qBjwQanwQQuFpbtMMjgvWCGzFTk7BYEl2NcZPgl8FW00xZGRVvTF" :
  'pk_live_51LWrZyKbW5COXODMgD72wCMP7VQeTnWpoqZgjpoG1KpYEISZEfMOZYBO8aBpdAynv3M1ServKOKj6DxgPNwTy7y100d28PNzWn';
export const REFERRAL_KEY = "referral";
export const PROMO_KEY = 'promo';
