import React, { useState } from 'react'

function YesNoButtonGroup({ handleChange }) {
  const [isYes, setIsYes] = useState(null);
  const selectedStyle = 'bg-black text-white';
  const unselectedStyle = 'bg-white text-gray-900'

  const handleClick = (newState) => {
    setIsYes(newState);
    handleChange(newState);
  }

  return (
    <div class="inline-flex rounded-md shadow-sm mt-1" role="group">
      <button onClick={() => handleClick(true)} type="button" class={`${isYes === true ? selectedStyle : unselectedStyle} inline-flex items-center py-2 px-4 text-sm font-medium rounded-l-lg border border-gray-200`}>
        Yes
      </button>
      <button onClick={() => handleClick(false)} type="button" class={`${isYes === false ? selectedStyle : unselectedStyle} inline-flex items-center py-2 px-4 text-sm font-medium rounded-r-md border border-gray-200`}>
        No
      </button>
    </div>
  )
}

export default YesNoButtonGroup;