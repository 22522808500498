import React from 'react'
import CheckoutSection from './components/CheckoutSection';
import DefaultNav from './components/DefaultNav';
import Loading from './components/Loading';
import { CheckoutContextProvider, useCheckoutContext } from './contexts/CheckoutContext';
import { useUserContext } from './contexts/UserContext';

const Checkout = (props) => {
  const { loading } = useUserContext();
  const { loading: checkoutLoading } = useCheckoutContext();

  return (
    <>
      <DefaultNav />
      <section
        class="pt-24 md:mt-0 md:h-screen flex flex-col md:text-left lg:px-48 md:px-12 px-4 bg-white">
        {loading || checkoutLoading ? <Loading /> : <CheckoutSection {...props} />}
      </section>

    </>
  )
}

const DefaultCheckout = (props) =>
  <CheckoutContextProvider><Checkout {...props} /></CheckoutContextProvider>;


export default DefaultCheckout;