import React, { useState } from 'react'
import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useCheckoutContext } from '../contexts/CheckoutContext';
import { useUserContext } from '../contexts/UserContext';
import { updateUserDoc } from '../firebase';
import DiscordInformation from './DiscordInformation';
import Loading from './Loading';
import { REFERRAL_KEY, REST_DOMAIN } from './Util';
import YesNoButtonGroup from './YesNoButtonGroup';

function RegisterDiscordProfile({ setActiveStep }) {
  const [hasDiscord, setHasDiscord] = useState(null);
  const [joinedServer, setJoinedServer] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useUserContext();
  const { username, setUsername, selectedLocations } = useCheckoutContext();
  const [searchParams] = useSearchParams();

  const isContinueDisabled = username == null || !username.includes("#") || selectedLocations.length === 0;

  const handleContinue = useCallback(async () => {
    setIsLoading(true);
    const joined = await fetch(`${REST_DOMAIN}/user-guild-check?username=${encodeURIComponent(username)}`, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((data) => data.success);

    if (!joined) {
      setJoinedServer(false);
      setIsLoading(false);
      return
    }

    await updateUserDoc(user, username, selectedLocations.map(location => location.id), searchParams.get(REFERRAL_KEY))
    setIsLoading(false);
    setActiveStep(prev => prev + 1)
  }, [selectedLocations, setActiveStep, user, username, searchParams])

  return (
    <>
      <div class='mt-4'>
        <p class="font-medium text-slate-700">Do you have a Discord account?</p>
        <YesNoButtonGroup handleChange={setHasDiscord} />
      </div>
      {hasDiscord != null &&
        <>
          <div class='mt-4'>
            <DiscordInformation hasDiscord={hasDiscord} />
          </div>
          <div class='mt-4'>
            <p class="font-medium text-slate-700">Please enter your Discord username (All usernames include a hashtag and numbers) </p>
            <p class="text-xs"><a target="_blank" rel="noreferrer" class="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="https://pcstrike.com/how-to-find-discord-user-id/">Need help finding it?</a></p>
            <input onChange={(e) => { setUsername(e.target.value) }} class={`${joinedServer === false && 'border-red-500'} mt-2 shadow appearance-none rounded-lg border border-gray-200 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`} type="text" placeholder="Username#1234" />
            {joinedServer === false && <p class="text-xs text-red-600">Did not detect member with this username in server. Make sure you've followed link to join server and username is spelled correctly.</p>}
          </div>
          <div class='mt-4 flex justify-end	'>
            <button disabled={isContinueDisabled} class="disabled:opacity-25 bg-black px-3 py-1 rounded-lg border border-gray-200 text-white mr-2 mb-2" onClick={handleContinue}>
              {isLoading ? <Loading /> : 'Continue'}
            </button>
          </div>
        </>
      }
    </>
  )
}

export default RegisterDiscordProfile