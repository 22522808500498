import React from 'react'
import { getFormattedDate, locationLabel } from './Util';

function SelectedLocationsList({ locations, handleDeletion }) {
  if (locations.length <= 0) {
    return null;
  }

  return locations.map(location => {
    return <div key={location.id} class="mt-2">
      <div class="flex items-center">
        <img src="https://www.svgrepo.com/show/359065/remove.svg" class="w-6 h-6 cursor-pointer" alt="" onClick={() => handleDeletion(location)} />
        <div class="ml-1">
          <p class="font-medium text-slate-700">{locationLabel(location)}</p>
          {location.last_updated ? <p class="text-xs">Last alert was {getFormattedDate(location.last_updated)}</p> : <p class="text-xs">We haven't detected any openings within the next 30 days for this location yet. </p>}
        </div>
      </div>
    </div>
  });
}

export default SelectedLocationsList