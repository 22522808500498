import React, { useCallback, useState } from 'react'
import { useCheckoutContext } from '../contexts/CheckoutContext';
import Loading from './Loading';
import { updateSMSUserDoc } from '../firebase';
import { useUserContext } from '../contexts/UserContext';
import { useSearchParams } from 'react-router-dom';
import { REFERRAL_KEY } from './Util';

const NUMBER_PREFIX = "+1";

function RegisterSMSProfile({ setActiveStep }) {
  const { user } = useUserContext();
  const { selectedLocations, phoneNumber, setPhoneNumber } = useCheckoutContext();
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams] = useSearchParams();

  const isContinueDisabled = selectedLocations.length === 0 || phoneNumber.length < 10;

  const formattedNumber = useCallback(() => {
    if (!phoneNumber) return NUMBER_PREFIX;

    if (phoneNumber.length < 4) return `${NUMBER_PREFIX} ${phoneNumber}`;
    if (phoneNumber.length < 7) return `${NUMBER_PREFIX} ${phoneNumber.slice(0, 3)} ${phoneNumber.slice(3)}`;

    return `${NUMBER_PREFIX} ${phoneNumber.slice(0, 3)} ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6)}`;
  }, [phoneNumber]);

  const handlePhoneNumberChange = useCallback((e) => {
    // Cut off the prefix digit
    const newNumber = e.target.value.replace(/[^\d]/g, '').slice(1);

    if (newNumber.length <= 10) {
      setPhoneNumber(newNumber);
    }
  }, [setPhoneNumber])

  const handleContinue = useCallback(async () => {
    await updateSMSUserDoc(user, `${NUMBER_PREFIX}${phoneNumber}`, selectedLocations.map(location => location.id), searchParams.get(REFERRAL_KEY))
    setIsLoading(false);
    setActiveStep(prev => prev + 1)
  }, [phoneNumber, searchParams, selectedLocations, setActiveStep, user])

  return (
    <>
      <div class='mt-4'>
        <p class="font-medium text-slate-700">Please enter in phone number for notifications:</p>
        <input value={formattedNumber()} onChange={handlePhoneNumberChange} class={`mt-2 shadow appearance-none rounded-lg border border-gray-200 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`} type="text" placeholder="(888) 888-8888" />
      </div>
      <div class='mt-4 flex justify-end	'>
        <button disabled={isContinueDisabled} class="disabled:opacity-25 bg-black px-3 py-1 rounded-lg border border-gray-200 text-white mr-2 mb-2" onClick={handleContinue}>
          {isLoading ? <Loading /> : 'Continue'}
        </button>
      </div>
    </>
  )
}

export default RegisterSMSProfile