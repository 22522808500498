import React, { useEffect, useState } from 'react'
import Login from './Login';
import Stepper from './Stepper';
import { useUserContext } from '../contexts/UserContext';
import RegisterProfile from './RegisterProfile';
import Payment from './Payment';

const labels = ['Login', 'Profile', 'Checkout'];

const Step = ({ activeStep, setActiveStep, product }) => {
  if (activeStep === 0) {
    return <Login />;
  } else if (activeStep === 1) {
    return <RegisterProfile setActiveStep={setActiveStep} product={product} />;
  } else {
    return <Payment product={product} />
  }
}

const clientSecret = new URLSearchParams(window.location.search).get(
  "payment_intent_client_secret"
);

const defaultActtiveStep = (user) => {
  if (!user) {
    return 0;
  } else if (clientSecret) {
    return 2;
  } else {
    return 1;
  }
}

const CheckoutSection = ({ product }) => {
  const { user } = useUserContext();
  const [activeStep, setActiveStep] = useState(defaultActtiveStep(user));

  useEffect(() => {
    if (!user) {
      setActiveStep(0);
    }
    // If client secret in URL then go straight to payment step
    else if (clientSecret) {
      return;
    }
    else if (user) {
      setActiveStep(1);
    }
  }, [user]);

  return (
    <div class="w-full">
      <Stepper labels={labels} activeStep={activeStep} />
      <div class="p-4">
        <Step activeStep={activeStep} setActiveStep={setActiveStep} product={product} />
      </div>
    </div>
  )
}

export default CheckoutSection