import React from 'react'
import LocationSearch from './LocationSearch';
import { Products } from '../enums/Products';
import RegisterDiscordProfile from './RegisterDiscordProfile';
import RegisterSMSProfile from './RegisterSMSProfile';

function RegisterProfile({ setActiveStep, product }) {
  return (
    <div class="max-w-lg mx-auto my-10 bg-white p-8 rounded-xl shadow shadow-slate-300">
      <LocationSearch />
      {product === Products.DISCORD ? <RegisterDiscordProfile setActiveStep={setActiveStep} /> : <RegisterSMSProfile setActiveStep={setActiveStep} />}
    </div >
  )
}

export default RegisterProfile