import React, { useCallback, useState } from 'react'
import PlaneIcon from '../assets/img/Plane.svg';
import MenuIcon from '../assets/img/Menu.svg';
import Cross from '../assets/img/Cross.svg';
import { Link } from 'react-router-dom';
import { useUserContext } from '../contexts/UserContext';
import Loading from './Loading';

const NavButton = () => {
  const { userDoc, loading, user } = useUserContext();
  const isPremium = userDoc?.is_premium;

  if (loading) {
    return <Loading />;
  }

  return isPremium === true && user != null ? <Link to="/profile" >
    <button class="py-2 px-4 text-white bg-black rounded-3xl">
      Profile
    </button> </Link> :
    <a href="#pricing">
      <button class="py-2 px-4 text-white bg-black rounded-3xl">
        Sign up
      </button>
    </a>;
}

const Nav = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = useCallback(() => { setIsOpen(prev => !prev) }, []);

  return (
    <>
      <nav class="fixed flex justify-between py-6 w-full lg:px-48 md:px-12 px-4 content-center bg-secondary z-10">
        <div class="flex items-center cursor-pointer" onClick={() => window.scrollTo(0, 0)}>
          <img src={PlaneIcon} alt="Logo" class="h-4" />
          <span class="font-pt-serif text-1xl font-bold ml-2">GlobalEntryNotif</span>
        </div>
        <ul class="font-montserrat items-center hidden md:flex">
          <li class="mx-3 ">
            <a class="growing-underline" href="#how-it-works">
              How it works
            </a>
          </li>
          <li class="growing-underline mx-3">
            <a href="#features">Features</a>
          </li>
          <li class="growing-underline mx-3">
            <a href="#pricing">Pricing</a>
          </li>
          <li class="growing-underline mx-3">
            <a href="#faq">FAQ</a>
          </li>
        </ul>
        <div class="font-montserrat hidden md:block">
          <NavButton />
        </div>
        <div id="showMenu" class="md:hidden cursor-pointer" onClick={toggleOpen}>
          <img src={MenuIcon} alt="Menu icon" />
        </div>
      </nav>
      <div id='mobileNav' class={`${!isOpen && 'hidden'} px-4 py-6 fixed top-0 left-0 h-full w-full bg-secondary z-20 animate-fade-in-down`}>
        <div id="hideMenu" class="flex justify-end cursor-pointer" onClick={toggleOpen}>
          <img src={Cross} alt="" class="h-16 w-16" />
        </div>
        <ul class="font-montserrat flex flex-col mx-8 my-24 items-center text-3xl">
          <li class="my-6">
            <a href="#how-it-works" onClick={toggleOpen}>How it works</a>
          </li>
          <li class="my-6">
            <a href="#features" onClick={toggleOpen}>Features</a>
          </li>
          <li class="my-6">
            <a href="#pricing" onClick={toggleOpen}>Pricing</a>
          </li>
          <li class="my-6">
            <a href="#faq" onClick={toggleOpen}>FAQ</a>
          </li>
        </ul>
      </div>
    </>
  )
}

export default Nav