import React from 'react'

import DiscordPriceCard from './DiscordPriceCard';
import SMSPriceCard from './SMSPriceCard';

const Pricing = () => {
  return (
    <section class="sectionSize bg-secondary py-0 scroll-mt-24" id="pricing">
      <div>
        <h2 class="secondaryTitle bg-underline4 mb-0 bg-100%">Pricing</h2>
      </div>
      <div class="flex w-full flex-col md:flex-row justify-center">
        <DiscordPriceCard />
        <SMSPriceCard />
      </div>
    </section>
  )
}

export default Pricing