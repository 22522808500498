
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {
  GoogleAuthProvider,
  FacebookAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  sendSignInLinkToEmail,
  isSignInWithEmailLink,
  signInWithEmailLink,
  signOut,
} from "firebase/auth";
import {
  getFirestore,
  query,
  getDocs,
  collection,
  addDoc,
  setDoc,
  doc,
  getDoc,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { getLocalWithTTL, setLocalWithTTL } from "./components/Util";
import { Products } from "./enums/Products";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBQQLTUhrZX43dLUx8ZHPtZowBGfW5GHbI",
  authDomain: "global-entry-notif.firebaseapp.com",
  projectId: "global-entry-notif",
  storageBucket: "global-entry-notif.appspot.com",
  messagingSenderId: "111752843080",
  appId: "1:111752843080:web:08e8406eba985107abed7d",
  measurementId: "G-NMD5Y7Q80D"
};

// Initialize Firebase
// https://firebase.google.com/docs/emulator-suite/connect_firestore
const app = initializeApp(firebaseConfig);
getAnalytics(app);
const auth = getAuth(app);
const db = getFirestore(app);

// Commenting out this irritated me off for too long no idea why it doesn't work
// if (process.env.NODE_ENV === 'development') {
//   connectFirestoreEmulator(db, 'localhost', 8080);
//   connectAuthEmulator(auth, 'http://localhost:9099');
// }


const googleProvider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();

facebookProvider.setCustomParameters({
  'display': 'popup'
});

function memoize(method) {
  let cache = {};

  return async function () {
    let args = JSON.stringify(arguments);
    cache[args] = cache[args] || method.apply(this, arguments);
    return cache[args];
  };
}

const createUserDoc = async (user, providerType) => {
  const docSnap = await getDoc(doc(db, `users/${user.uid}`))

  if (!docSnap.exists()) {
    await setDoc(doc(db, "users", user.uid), {
      uid: user.uid,
      name: user.displayName,
      authProvider: providerType,
      email: user.email,
    });
  }
}

const updateDiscordUserDoc = async (user, discordUsername, location_ids, referral) => {
  const docRef = doc(db, `users/${user.uid}`)
  await setDoc(docRef, { location_ids, discord_username: discordUsername, referral, notification_service: Products.DISCORD, mute_notifications: false }, { merge: true })
}

const updateSMSUserDoc = async (user, phoneNumber, location_ids, referral) => {
  const docRef = doc(db, `users/${user.uid}`)
  await setDoc(docRef, { location_ids, phone_number: phoneNumber, referral, notification_service: Products.SMS, mute_notifications: false }, { merge: true })
}

const getLocationDocs = memoize(async () => {
  var locations = getLocalWithTTL('locations');

  if (locations != null) {
    return locations;
  }
  const q = query(collection(db, 'locations'));
  const querySnapshot = await getDocs(q);
  locations = querySnapshot.docs.map(doc => doc.data());

  return setLocalWithTTL('locations', locations, 5 * 1000 * 60);
});

const getLocationDoc = async (locationID) => {
  const location = await getDoc(doc(db, `locations/${locationID}`));

  return location.data();
}

const getUserDoc = async (user) => {
  const privateUserDocSnap = await getDoc(doc(db, `users/${user.uid}/private/private`));
  const userDocSnap = await getDoc(doc(db, `users/${user.uid}`));

  return { ...privateUserDocSnap.data(), ...userDocSnap.data() };
}

const useUserDoc = (user) => {
  const [loading, setLoading] = useState(true);
  const [doc, setDoc] = useState(null);

  useEffect(() => {
    if (user != null) {
      setLoading(true);
      getUserDoc(user).then((doc) => {
        setDoc(doc);
        setLoading(false)
      });
    } else {
      setLoading(false);
    }
  }, [user]);

  return [doc, loading];
}

const useFetchLocationDocsByIds = (ids) => {
  const [loading, setLoading] = useState(true);
  const [docs, setDocs] = useState([]);

  useEffect(() => {
    setLoading(true);
    Promise.all(ids.map(id => getLocationDoc(id))).then(docs => {
      setDocs(docs);
      setLoading(false);
    }).catch(e => {
      setLoading(false);
      console.error(e);
    });
  }, [ids]);

  return [docs, loading];
}

const useLocationDocs = () => {
  const [loading, setLoading] = useState(true);
  const [docs, setDocs] = useState(null);

  useEffect(() => {
    getLocationDocs().then((locations) => {
      setDocs(locations);
      setLoading(false)
    }).catch(e => {
      setLoading(false);
    });
  }, []);

  return [docs, loading];
}


const signInWithProvider = async (provider, providerType) => {
  try {
    const res = await signInWithPopup(auth, provider);
    await createUserDoc(res.user, providerType);
  } catch (err) {
    // TODO handle errors and give feedback to user
    console.error(err);
  }
}

const signInWithGoogle = async () => {
  await signInWithProvider(googleProvider, 'google');
};

const signInWithFacebook = async () => {
  await signInWithProvider(facebookProvider, 'facebook');
}

const useSignInWithFirebaseEmailLink = () => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isSignInWithEmailLink(auth, window.location.href)) {
      // Additional state parameters can also be passed via URL.
      // This can be used to continue the user's intended action before triggering
      // the sign-in operation.
      // Get the email if available. This should be available if the user completes
      // the flow on the same device where they started it.
      let email = window.localStorage.getItem('emailForSignIn');
      if (!email) {
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again. For example:
        email = window.prompt('Looks like you logged in with a different device! Please provide your email for confirmation.');
      }
      setLoading(true);
      // The client SDK will parse the code from the link for you.
      signInWithEmailLink(auth, email, window.location.href)
        .then((result) => {
          // Clear email from storage.
          window.localStorage.removeItem('emailForSignIn');
          createUserDoc(result.user, "emailLink");
          setLoading(false);
        })
        .catch((error) => {
          // Some error occurred, you can inspect the code: error.code
          // Common errors could be invalid email and invalid or expired OTPs.
          console.error(error);
          setLoading(false);
        });

    }
  }, []);


  return [loading];
}

const sendEmailLink = (email) => {
  const actionCodeSettings = {
    url: `${window.location.href}`,
    handleCodeInApp: true,
  };
  return sendSignInLinkToEmail(auth, email, actionCodeSettings)
    .then(() => {
      // The link was successfully sent. Inform the user.
      // Save the email locally so you don't need to ask the user for it again
      // if they open the link on the same device.
      window.localStorage.setItem('emailForSignIn', email);
      // ...

    })
    .catch((err) => {
      // TODO handle errors and give feedback to user
      console.error(err);
    });
}


const logInWithEmailAndPassword = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    console.error(err);
  }
};

const registerWithEmailAndPassword = async (name, email, password) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    await addDoc(collection(db, "users"), {
      uid: user.uid,
      name,
      authProvider: "email",
      email,
    });
  } catch (err) {
    console.error(err);
  }
};

const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert("Password reset link sent!");
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const logout = () => {
  return signOut(auth);
};

export {
  auth,
  db,
  useLocationDocs,
  signInWithGoogle,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  logout,
  signInWithFacebook,
  useSignInWithFirebaseEmailLink,
  sendEmailLink,
  updateDiscordUserDoc as updateUserDoc,
  useUserDoc,
  updateSMSUserDoc,
  useFetchLocationDocsByIds
};