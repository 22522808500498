import React, { createContext, useContext, useState } from 'react'
import { useLocationDocs } from '../firebase';

const CheckoutContext = createContext();

const useCheckoutContext = () => {
  // get the context
  const context = useContext(CheckoutContext);

  // if `undefined`, throw an error
  if (context === undefined) {
    throw new Error("useCheckoutContext was used outside of its Provider");
  }

  return context;
};

function CheckoutContextProvider({ children }) {
  const [locations, locationsLoading] = useLocationDocs();
  // https://headlessui.com/react/combobox#styling-the-active-and-selected-option
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [username, setUsername] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState('');

  return (
    // the Provider gives access to the context to its children
    <CheckoutContext.Provider value={{ locations, loading: locationsLoading, setSelectedLocations, selectedLocations, username, setUsername, phoneNumber, setPhoneNumber }}>
      {children}
    </CheckoutContext.Provider>);
}

export { CheckoutContextProvider, useCheckoutContext };