import React from 'react'

const Stepper = ({ labels, activeStep }) => {

  const getColor = (step) => {
    if (step < activeStep) {
      return 'bg-green-600 text-white';
    } else if (step === activeStep) {
      return 'bg-blue-600 text-white';
    } else {
      return 'bg-gray-100';
    }
  }

  return (
    <div>
      <h2 class="sr-only">Steps</h2>

      <div
        class="relative after:inset-x-0 after:h-0.5 after:absolute after:top-1/2 after:-translate-y-1/2 after:block after:rounded-lg after:bg-gray-100"
      >
        <ol
          class="relative z-10 flex justify-between text-sm font-medium text-gray-500"
        >
          {labels.map((label, i) => (<li key={i} class="flex items-center p-2 bg-white">
            <span
              class={`w-6 h-6 text-[10px] font-bold leading-6 ${getColor(i)} text-center rounded-full`}
            >
              {i + 1}
            </span>

            <span class="hidden sm:block sm:ml-2"> {label} </span>
          </li>))}
        </ol>
      </div>
    </div>
  )
}

export default Stepper