import React from 'react'
import Twitter from '../assets/img/Twitter.svg';
import Logo from '../assets/img/Plane_white.svg';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <section class="bg-black sectionSize">
      <div class="mb-4 flex items-center">
        <img src={Logo} alt="Logo" class="h-4" />
        <span class="font-pt-serif text-1xl font-bold ml-2 text-white ">GlobalEntryNotif</span>
      </div>
      <div class="flex mb-6">
        <a href="https://twitter.com/EntryNotif">
          <img src={Twitter} alt="Twitter logo" class="mx-4" />
        </a>
      </div>
      <div class="flex mb-3">
        <div class="text-white font-montserrat text-sm">
          <Link to="/termsofservice">
            Terms of Service
          </Link>
        </div>
        <div class="text-white font-montserrat text-sm ml-4">
          <Link to="/privacy">
            Privacy
          </Link>
        </div>
        <div class="text-white font-montserrat text-sm ml-4">
          <a href='https://twitter.com/EntryNotif'>
            Contact
          </a>
        </div>
      </div>
      <div class="text-white font-montserrat text-sm">
        © 2022 GlobalEntryNotif. All rights reserved
      </div>
    </section>
  )
}

export default Footer