import React from 'react'

const Features = () => {
  return (
    <section class="sectionSize bg-secondary scroll-mt-24" id="features">
      <div>
        <h2 class="secondaryTitle bg-underline3 bg-100%">Features</h2>
      </div>
      <div class="md:grid md:grid-cols-2 md:grid-rows-1">

        <div class="flex items-start font-montserrat my-6 mr-10">
          <div>
            <h3 class="font-semibold text-2xl">Discord Push Notifications</h3>
            <p>
              Get notified instantly for locations selected upon sign up. Push notifications will be sent
              to all surfaces that the Discord application is downloaded (Ex. mobile, desktop etc.)
            </p>
          </div>
        </div>

        <div class="flex items-start font-montserrat my-6 mr-10">
          <div>
            <h3 class="font-semibold text-2xl">SMS Notifications</h3>
            <p>
              Get notified instantly via text message for every new appointment opening that has been detected.
            </p>
          </div>
        </div>

        <div class="flex items-start font-montserrat my-6 mr-10">
          <div>
            <h3 class="font-semibold text-2xl">Quick Link</h3>
            <p>
              Each notification will come with a link so you can quickly access the appointment website to
              claim your interview slot.
            </p>
          </div>
        </div>

      </div>
    </section>
  )
}

export default Features