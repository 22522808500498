import React from "react";
import CheckIcon from "../assets/img/CheckedBox.svg";
import { DISCORD_PRICE } from "./Util";
import useProductHook from "../hooks/useProuductPrice";
import { Products } from "../enums/Products";

function DiscordPriceCard() {
  const [discordPrice] = useProductHook(Products.DISCORD);

  const price = discordPrice == null ? DISCORD_PRICE : discordPrice;
  const isDiscounted = price !== DISCORD_PRICE;

  return (
    <div class="flex-1 flex flex-col mx-6 shadow-2xl relative bg-secondary rounded-2xl py-5 px-8 my-8 md:top-12 max-w-sm">
      <h3 class="font-pt-serif font-normal text-2xl mb-4">
        Discord Notifications
      </h3>
      <div class="font-montserrat font-bold text-2xl mb-4">
        <span class={`${isDiscounted && "line-through"}`}>
          ${DISCORD_PRICE}
        </span>
        {isDiscounted && <span> ${price}</span>}
        <span class="font-normal text-base"> for 30 days</span>
      </div>
      <div class="flex">
        <img src={CheckIcon} alt="" class="mr-1" />
        <p>Choose up to 5 enrollment centers</p>
      </div>
      <div class="flex">
        <img src={CheckIcon} alt="" class="mr-1" />
        <p>No recurring payments</p>
      </div>
      <div class="flex">
        <img src={CheckIcon} alt="" class="mr-1" />
        <p>Multi device notifications</p>
      </div>
      <div class="flex">
        <img src={CheckIcon} alt="" class="mr-1" />
        <p>Instant push notification</p>
      </div>
      <div class="flex">
        <img src={CheckIcon} alt="" class="mr-1" />
        <p>100% money back guarantee*</p>
      </div>
      <div class="flex">
        <img src={CheckIcon} alt="" class="mr-1" />
        <p>Mute notifications at any time</p>
      </div>
      <button
        disabled
        class="disabled:bg-gray-300 w-full border-2 border-solid border-black rounded-xl text-lg py-3 mt-4"
      >
        Discontinued
      </button>
      <div class="mt-3">
        <p class="text-xs">
          * Refund is available if all selected locations failed to receive at
          least 3 notifications
        </p>
      </div>
    </div>
  );
}

export default DiscordPriceCard;
