import React from 'react'
import iPhone from '../assets/img/iphone.webp';

const Landing = () => {
  return (
    <section
      class="pt-24 md:mt-0 md:h-screen flex flex-col justify-center text-center md:text-left md:flex-row md:justify-between md:items-center lg:px-48 md:px-12 px-4 bg-secondary">
      <div class="md:flex-1 md:mr-10">
        <h1 class="font-pt-serif text-5xl font-bold mb-7">
          Get notified for Global Entry interview appointments&nbsp;
          <span class="bg-underline1 bg-left-bottom bg-no-repeat pb-2 bg-100% inline-block">
            instantly
          </span>
        </h1>
        <p class="font-pt-serif font-normal mb-7">
          Global Entry interview wait times exceed a year in some areas. New interview appointments are frequently released for the upcoming week. Cut your wait time and secure your interview by receiving push notifications via mobile or desktop as soon as an interview slot opens.
        </p>
        <div class="font-montserrat">
          <a href="#pricing">
            <button class="bg-black px-6 py-4 rounded-lg border-2 border-black border-solid text-white mr-2 mb-2">
              Sign up
            </button>
          </a>
        </div>
      </div>
      <div class="flex justify-around md:block mt-8 md:mt-0 md:flex-1">
        <img src={iPhone} alt="iPhone" class="m-auto" style={{ height: "600px" }} />
      </div>
    </section>
  )
}

export default Landing