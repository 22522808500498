import React, { useCallback, useState } from 'react'
import { Combobox, Transition } from '@headlessui/react'
import { locationLabel, locationQuery } from './Util';
import SelectedLocationsList from './SelectedLocationsList';
import { useCheckoutContext } from '../contexts/CheckoutContext';

const LOCATION_LIMIT = 5;

function LocationSearch() {
  const [query, setQuery] = useState('')
  const { locations, setSelectedLocations, selectedLocations } = useCheckoutContext();

  const filteredLocations = locations.filter((location) => {
    return locationQuery(location, query);
  });

  const handleSelection = useCallback((location) => {
    setSelectedLocations(oldSelectedLocations => oldSelectedLocations.filter(l => l.id !== location.id).concat(location));
  }, [setSelectedLocations]);

  const handleDeletion = useCallback((location) => {
    setSelectedLocations(oldSelectedLocations => oldSelectedLocations.filter(l => l.id !== location.id));
  }, [setSelectedLocations]);

  return (
    <div className="relative">
      <p class="font-medium text-slate-700">{`Choose up to ${LOCATION_LIMIT} locations to receive notifications  :`}</p>
      <Combobox onChange={handleSelection} disabled={selectedLocations.length >= LOCATION_LIMIT}>
        <Combobox.Input placeholder='Search by city, state, name, or id...' onChange={(event) => setQuery(event.target.value)} className="mt-1 shadow appearance-none rounded-lg border border-gray-200 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
        <Transition
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
        >
          <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredLocations.map((location) => (
              <Combobox.Option key={location.id} value={location} className={({ active }) =>
                `relative cursor-default select-none py-2 pl-10 pr-4 z-20 ${active ? 'bg-black text-white' : 'text-gray-900'
                }`
              }>
                {locationLabel(location)}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        </Transition>
      </Combobox>
      <SelectedLocationsList locations={selectedLocations} handleDeletion={handleDeletion} />
    </div>
  )
}

export default LocationSearch