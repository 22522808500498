import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { PROMO_KEY, REFERRAL_KEY, REST_DOMAIN } from '../components/Util';

export default function useProductHook(product) {
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(true);
  const [price, setPrice] = useState(null);

  const referral = searchParams.get(REFERRAL_KEY);
  const promo = searchParams.get(PROMO_KEY);

  useEffect(() => {
    fetch(`${REST_DOMAIN}/product?promo=${encodeURIComponent(promo)}&referral=${encodeURIComponent(referral)}&product=${product}`, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then(data => {
        setPrice(data.price);
      }).finally(() => {
        setIsLoading(false);
      });
  }, [product, promo, referral]);

  return [price, isLoading];
}