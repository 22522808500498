import React from 'react'

const inviteLink = 'https://discord.gg/bzkKgNuVTT';
const hasDiscordText = "Great! Click this link to join our Discord server before continuing:";
const newDiscordText = "We've integrated with Discord, a messaging platform, that will send you a notification when an appointment opens. Follow this link to register and automatically join our Discord server:";

function DiscordInformation({ hasDiscord }) {
  return (
    <div>
      {hasDiscord ?
        <p class="font-medium text-slate-700">{hasDiscordText} </p> :
        <p class="font-medium text-slate-700">{newDiscordText} </p>}
      <a target="_blank" rel="noreferrer" class="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href={inviteLink}>{inviteLink}</a>
    </div>
  );
}

export default DiscordInformation