import React, { useState } from 'react'
import CaretRight from '../assets/img/CaretRight.svg';

const FAQItem = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div class="w-full py-4">
        <div class="flex justify-between items-center cursor-pointer" onClick={() => setIsOpen(oldIsOpen => !oldIsOpen)}>
          <div class="font-montserrat font-medium mr-auto">
            {title}
          </div>
          <img src={CaretRight} alt="" class={`transform transition-transform ${isOpen && "rotate-90"}`} />
        </div>
        {isOpen && <div class="font-montserrat text-sm font-extralight pb-8">
          {children}
        </div>}
      </div>
      <hr class="w-full bg-white" />
    </>
  )
}

export default FAQItem