import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Checkout from './Checkout';
import { UserContextProvider } from './contexts/UserContext';
import PrivacyPolicy from './PrivacyPolicy';
import TermsOfService from './TermsOfService';
import Profile from './Profile';
import { ErrorBoundary } from 'react-error-boundary'
import ErrorModal from './components/ErrorModal';
import { Products } from './enums/Products'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <UserContextProvider>
        <ErrorBoundary FallbackComponent={ErrorModal}>
          <Routes>
            <Route path="/" element={<App />} />
            <Route path="checkout/SMS" element={<Checkout product={Products.SMS} />} />
            <Route path="checkout/Discord" element={<Checkout product={Products.DISCORD} />} />
            <Route path="privacy" element={<PrivacyPolicy />} />
            <Route path="termsofservice" element={<TermsOfService />} />
            <Route path="profile" element={<Profile />} />
          </Routes>
        </ErrorBoundary>
      </UserContextProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
