// Great write up: https://devtrium.com/posts/how-use-react-context-pro
import React, { createContext, useContext } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, useSignInWithFirebaseEmailLink, useUserDoc } from '../firebase';

const UserContext = createContext();

const useUserContext = () => {
  // get the context
  const context = useContext(UserContext);

  // if `undefined`, throw an error
  if (context === undefined) {
    throw new Error("useUserContext was used outside of its Provider");
  }

  return context;
};

function UserContextProvider({ children }) {
  const [user, loading, error] = useAuthState(auth);
  const [userDoc, userDocLoading] = useUserDoc(user);
  const [signInWithLinkLoading] = useSignInWithFirebaseEmailLink();

  return (
    // the Provider gives access to the context to its children
    <UserContext.Provider value={{ userDoc, user, loading: loading || signInWithLinkLoading || userDocLoading, error }}>
      {children}
    </UserContext.Provider>);
}

export { UserContextProvider, useUserContext };