import moment from 'moment';
import React from 'react'
import DefaultNav from './components/DefaultNav'
import Loading from './components/Loading';
import { getFormattedDate, locationLabel } from './components/Util';
import { useUserContext } from './contexts/UserContext'
import { useFetchLocationDocsByIds } from './firebase';

function Profile() {
  const { userDoc, loading } = useUserContext();
  const location_ids = userDoc?.location_ids ?? [];

  const [locations, locationsLoading] = useFetchLocationDocsByIds(location_ids);

  const discordUsername = userDoc["discord_username"];
  const phoneNumber = userDoc['phone_number'];

  return (
    <>
      <DefaultNav />
      <section class="pt-24 md:mt-0 md:h-screen flex flex-col md:text-left lg:px-48 md:px-12 px-4 bg-white">
        <div class="max-w-lg mx-auto my-10 bg-white p-8 rounded-xl shadow shadow-slate-300">
          {loading || userDoc == null || locationsLoading ? <Loading /> : <>
            <p class="text-2xl font-bold text-green-500"> Paid </p>
            <p class="text-xs"> {moment.unix(userDoc["paid_timestamp"]).format("MM/DD/YYYY")} </p>
            {discordUsername != null && <div class="mt-4">
              <p class="font-medium text-slate-700">Discord Username </p>
              <input disabled={true} value={discordUsername} class={`mt-2 shadow appearance-none rounded-lg border border-gray-200 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`} type="text" placeholder="Username#1234" />
            </div>}
            {phoneNumber != null && <div class="mt-4">
              <p class="font-medium text-slate-700">Phone Number: </p>
              <input disabled={true} value={phoneNumber} class={`mt-2 shadow appearance-none rounded-lg border border-gray-200 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`} type="text" placeholder="Username#1234" />
            </div>}
            {locations.map(location => <div class="mt-4" key={location['id']}>
              <p class="font-medium text-slate-700">{locationLabel(location)}</p>
              {location.last_updated ? <p class="text-xs">Last alert was {getFormattedDate(location.last_updated)}</p> : <p class="text-xs">We haven't detected any openings within the next 30 days for this location yet. </p>}
            </div>)}
          </>
          }
        </div>
      </section>
    </>
  )
}

export default Profile