import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useUserContext } from '../contexts/UserContext';
import { logout } from '../firebase';
import PlaneIcon from '../assets/img/Plane.svg';
import { useCallback } from 'react';

function DefaultNav() {
  const { user } = useUserContext();
  const navigate = useNavigate();

  const handleSignOut = useCallback(() => {
    logout().then(() => navigate('/'));
  }, [navigate])

  return (
    <nav class="fixed flex justify-between py-6 w-full lg:px-48 md:px-12 px-4 content-center bg-secondary z-50">
      <Link to="/">
        <div class="flex items-center cursor-pointer h-full">
          <img src={PlaneIcon} alt="Logo" class="h-4" />
          <span class="font-pt-serif text-1xl font-bold ml-2">GlobalEntryNotif</span>
        </div>
      </Link>
      {user != null && <div class="font-montserrat block">
        <button class="py-2 px-4 text-white bg-black rounded-3xl" onClick={handleSignOut}>
          Sign out
        </button>
      </div>}
    </nav>
  )
}

export default DefaultNav